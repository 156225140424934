<template>
  <v-layout row wrap class="my-12 mx-0" align-center>
    <v-flex xs12 class="mt-12">
      <div class="mt-11 mx-2 justify-center" style="display: grid">
        <v-img
          :src="`${publicPath}/logo2.png`"
          alt="LuontoEnergia"
          position="center center"
          :max-width="
            $vuetify.breakpoint.xsOnly
              ? 400
              : $vuetify.breakpoint.xlOnly
              ? 600
              : 500
          "
        >
        </v-img>
        <v-divider style="border-color: #004b55" class="mx-12 mt-5" />
      </div>
    </v-flex>
    <v-flex xs12 class="my-0">
      <v-container>
        <v-layout row class="ma-0 text-center title">
          <v-flex xs12>
            Olemme uusiutuvia energiaratkaisuja tarjoava oululainen yritys
          </v-flex>
          <v-flex xs12>
            Teemme Avaimet käteen -toimituksia asiakkaan tarpeen mukaan
            kotitalouksille sekä yrityksille
          </v-flex>
          <v-flex xs12 class="mb-12">
            Yrityksen historia pohjautuu vuosien takaisiin asiakassuhteisiin ja
            luottamukseen
          </v-flex>
          <v-flex
            :class="
              this.$vuetify.breakpoint.smAndDown ? 'text-left' : 'text-center'
            "
            xs12
            md6
            xl3
            ><v-icon class="nightText"> {{ circle }} </v-icon>Laadukkaat
            tuotteet</v-flex
          >
          <v-flex
            :class="
              this.$vuetify.breakpoint.smAndDown ? 'text-left' : 'text-center'
            "
            xs12
            md6
            xl3
            ><v-icon class="nightText"> {{ circle }} </v-icon>
            Oma maahantuonti ja varasto
          </v-flex>
          <v-flex
            :class="
              this.$vuetify.breakpoint.smAndDown ? 'text-left' : 'text-center'
            "
            xs12
            md6
            xl3
            ><v-icon class="nightText text-no-wrap"> {{ circle }} </v-icon>Oma
            projektinhallintajärjestelmä</v-flex
          >
          <v-flex
            :class="
              this.$vuetify.breakpoint.smAndDown ? 'text-left' : 'text-center'
            "
            xs12
            md6
            xl3
            ><v-icon class="nightText"> {{ circle }} </v-icon
            >Laatujärjestelmä</v-flex
          >
        </v-layout>
      </v-container>
    </v-flex>
  </v-layout>
</template>

<script>
import { mdiCircleMedium } from "@mdi/js";
export default {
  name: "company",
  data: () => ({
    publicPath: process.env.VUE_APP_DEPLOY_URL || "",
    circle: mdiCircleMedium
  })
};
</script>

<style>
.bounce-enter-active {
  animation: bounce-in 0.5s;
}
</style>
